
import { Vue, Component, Prop, Emit } from "nuxt-property-decorator";
import HeaderMenuItem from "~/components/molecules/nav/Menu/HeaderMenuItem.vue";
import MyAccountIcon from "~/components/organisms/icon/MyAccountIcon.vue";
import menuTree from "~/assets/data/menu.json";
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import { getMkLogin, getMkLogout } from "~/operations";

@Component({
  components: { RouterLink, MyAccountIcon, HeaderMenuItem },
})
export default class HeaderMenu extends Vue {
  @Prop() isLoggedIn: boolean;
  @Emit()
  onClose() {
    return false;
  }

  menuTree = menuTree;

  get _getMkLogin() {
    return getMkLogin();
  }

  get _getMkLogout() {
    return getMkLogout();
  }
}
