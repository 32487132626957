
import { Prop, Vue, Component, Emit } from "nuxt-property-decorator";

@Component({})
export default class BasicOverlay extends Vue {
  @Prop({ default: false }) isVisible: boolean;

  @Emit()
  onClose() {
    return false;
  }
}
