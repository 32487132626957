export default async function (to, from, savedPosition) {
  if (savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          selector: savedPosition,
        });
      }, 600);
    });
  } else if (to.hash) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          selector: to.hash,
        });
      }, 600);
    });
  } else if (
    (from.path === "/bestellung/heizoel-checkout" &&
      to.path === "/bestellung/heizoel-checkout") ||
    (from.path === "/pelletspreise/pellets-checkout" &&
      to.path === "/pelletspreise/pellets-checkout")
  ) {
    return;
  } else return { x: 0, y: 0 };
}
