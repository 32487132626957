import { decodePlaceName, decodeUrlForPlace } from "~/operations/shared";
import { BreadCrumbDetail } from "~/types/BreadCrumb";

const cityBaseUrl = "/heizoelpreise";

const addHeizoelpreiseBreadcrumb = function (
  breadcrumbs: BreadCrumbDetail[],
): BreadCrumbDetail[] {
  return [
    {
      name: "",
      linkUrl: cityBaseUrl,
      linkValue: cityBaseUrl,
    },
    ...breadcrumbs,
  ];
};

const createStateBreadcrumb = function (state: string): BreadCrumbDetail {
  const encodedState = encodeURIComponent(state.toLowerCase());
  return {
    name: state,
    linkUrl: `${cityBaseUrl}/bundeslaender/${encodedState}`,
    linkValue: `${cityBaseUrl}/bundeslaender/${encodedState}`,
  };
};

const createCityStateBreadcrumb = function (state: string): BreadCrumbDetail {
  const stateUrlSegment = state.toLowerCase();
  return {
    name: `Kreisfreie Stadt ${state}`,
    linkUrl: `bundeslaender/${stateUrlSegment}/stadt-${stateUrlSegment}`,
    linkValue: `bundeslaender/${stateUrlSegment}/stadt-${stateUrlSegment}`,
    lastItem: false,
  };
};

const createKreisfreieStadtBreadcrumb = function (
  state: string,
  city: string,
): BreadCrumbDetail {
  const encodedState = encodeURIComponent(state.toLowerCase());
  const encodedCity = decodePlaceName(encodeURIComponent(city.toLowerCase()));
  return {
    name: `Kreisfreie Stadt ${city}`,
    linkUrl: `${cityBaseUrl}/bundeslaender/${encodedState}/stadt-${encodedCity}`,
    linkValue: `${cityBaseUrl}/bundeslaender/${encodedState}/stadt-${encodedCity}`,
  };
};

const createCountyBreadcrumb = function (
  state: string,
  county: string,
): BreadCrumbDetail {
  const encodedState = encodeURIComponent(state.toLowerCase());
  const encodedCounty = decodePlaceName(
    encodeURIComponent(county.toLowerCase()),
  );
  return {
    name: county,
    linkUrl: `${cityBaseUrl}/bundeslaender/${encodedState}/${encodedCounty}`,
    linkValue: `${cityBaseUrl}/bundeslaender/${encodedState}/${encodedCounty}`,
  };
};

const createCityBreadcrumb = function (
  city: string,
  zipcode: string,
): BreadCrumbDetail {
  return {
    name: city,
    zipcode,
    linkUrl: decodeUrlForPlace(`heizoelpreise-${city}-${zipcode}`),
    linkValue: decodeUrlForPlace(`heizoelpreise-${city}-${zipcode}`),
    lastItem: true,
  };
};

const formatBreadcrumbName = function (
  item: BreadCrumbDetail,
  index: number,
  totalLength: number,
) {
  if (index === 0) return "Heizölpreise";

  let name = `Heizölpreise ${item.name}`;

  if (
    (totalLength > 3 && index === totalLength - 2) ||
    (totalLength === 3 && index === totalLength - 1 && !item.zipcode)
  ) {
    name = `Heizölpreise Landkreis ${item.name}`;
  }

  if (item.zipcode) {
    name += ` ${item.zipcode}`;
  }

  return name;
};

const createBreadCrumbsStructuredData = function (
  breadCrumb: BreadCrumbDetail[],
) {
  return [
    {
      "@type": "ListItem",
      position: 1,
      item: {
        "@id": "https://www.esyoil.com",
        name: "Startseite",
      },
    },
    ...breadCrumb.map((item, index) => ({
      "@type": "ListItem",
      position: index + 2,
      item: {
        "@id": `https://www.esyoil.com${item.linkValue}`,
        name: formatBreadcrumbName(item, index, breadCrumb.length),
      },
    })),
  ];
};

export {
  addHeizoelpreiseBreadcrumb,
  createBreadCrumbsStructuredData,
  createCityBreadcrumb,
  createCityStateBreadcrumb,
  createCountyBreadcrumb,
  createKreisfreieStadtBreadcrumb,
  createStateBreadcrumb,
};
