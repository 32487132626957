
import { Component, namespace, Prop, Vue } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import { ButtonText } from "~/operations/messages";
import sendGtmEvent from "~/operations/send-gtm-event";

const CalculatorModule = namespace("calculator");

@Component({
  components: {
    RouterLink,
  },
})
export default class ReducedPriceComparison extends Vue {
  // Props with default values
  @Prop({ default: "small" }) variant!: "small" | "bordered";
  @Prop({ default: "all" }) paymentType!: string;
  @Prop({ default: "oil" }) inputType!: "oil" | "pellets";
  @Prop({ default: "Heizöl-Preisvergleich:" }) titleText!: string;
  @Prop({ default: "Liter" }) amountLabel!: string;
  @Prop({ default: "Ihr Bedarf" }) amountPlaceholder!: string;
  @Prop({ default: ButtonText.compareOilPrices }) buttonText!: string;
  @Prop({ default: 3000 }) defaultAmount!: number;
  @Prop({ default: 500 }) minAmount!: number;
  @Prop({ default: 32000 }) maxAmount!: number;

  // Data properties
  userZipCode = "";
  userAmount: number | string = this.defaultAmount;
  userZipCodeSupportMessage = "";
  userAmountSupportMessage = "";

  // Vuex actions and state
  @CalculatorModule.Action("fetchAvailableOptions")
  fetchAvailableOptions!: any;

  @CalculatorModule.State("availabilities")
  availabilities!: any;

  // Storage keys based on input type
  get storageKeys() {
    if (this.inputType === "pellets") {
      return {
        zipCode: "pelletsZipCode",
        amount: "pelletsAmount",
      };
    }
    return {
      zipCode: "userZipCode", // Keep original key for oil
      amount: "userOilAmount", // Keep original key for oil
    };
  }

  mounted() {
    this.tryLocalStoragePreset();
    this.onResize();
    window.addEventListener("resize", this.onResize);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize() {
    const zipInput = document.getElementById(
      `header-${this.inputType}-zip-input`,
    ) as HTMLInputElement;
    const inputLabel = zipInput.parentNode.firstChild as HTMLElement;

    if (window.innerWidth <= 400) {
      inputLabel.innerHTML = "PLZ";
      zipInput.placeholder = "21339";
    } else {
      inputLabel.innerHTML = "Postleitzahl";
      zipInput.placeholder = "z.B. 21339";
    }
  }

  tryLocalStoragePreset() {
    // Get zipcode based on input type
    const userPresetZipCode = localStorage.getItem(this.storageKeys.zipCode);
    if (userPresetZipCode && userPresetZipCode.length === 5) {
      this.userZipCode = userPresetZipCode;
    }

    // Get amount based on input type
    const userPresetAmount = Number(
      localStorage.getItem(this.storageKeys.amount),
    );
    if (userPresetAmount && userPresetAmount >= this.minAmount) {
      this.userAmount = userPresetAmount;
    }
  }

  setLocalStoragePreset(zipcode: string, amount: number) {
    if (zipcode.length === 5) {
      localStorage.setItem(this.storageKeys.zipCode, zipcode);
    }
    localStorage.setItem(this.storageKeys.amount, amount.toString());
  }

  setButtonVariant() {
    if (this.variant === "small") return "small";
    if (this.variant === "bordered") return "black";
    return this.inputType === "pellets" ? "primary" : "default";
  }

  validateInputs() {
    let isValid = true;
    const zipcodeErrorMsg = "Ungültige Postleitzahl";

    // Validate ZIP code
    if (!this.userZipCode || this.userZipCode.length !== 5) {
      this.userZipCodeSupportMessage = zipcodeErrorMsg;
      isValid = false;
    } else {
      this.userZipCodeSupportMessage = "";
    }

    // Validate amount
    const amount = Number(this.userAmount);
    if (!this.userAmount || amount < this.minAmount) {
      this.userAmountSupportMessage = `Minimum ${this.minAmount.toLocaleString()} ${
        this.amountLabel
      }`;
      isValid = false;
    } else if (amount > this.maxAmount) {
      this.userAmountSupportMessage = `Maximum ${this.maxAmount.toLocaleString()} ${
        this.amountLabel
      }`;
      isValid = false;
    } else {
      this.userAmountSupportMessage = "";
    }

    return isValid;
  }

  async redirectToComparsionPage() {
    if (!this.validateInputs()) {
      return;
    }

    this.setLocalStoragePreset(this.userZipCode, Number(this.userAmount));

    try {
      if (this.inputType === "pellets") {
        await this.fetchAvailableOptions({
          isPellets: true,
          zipcode: this.userZipCode,
          amount: this.userAmount,
        });

        this.$router.push({
          path: "/pelletspreise/bestellung",
          query: this.getQueryParams(),
        });
      } else {
        await this.fetchAvailableOptions({
          isPellets: false,
          zipcode: this.userZipCode,
          amount: this.userAmount,
        });

        this.$router.push({
          path: "/bestellung",
          query: this.getQueryParams(),
        });
      }

      this.sendAnalyticsEvent();
    } catch (error) {
      console.error("Error fetching available options:", error);
    }
  }

  private getQueryParams(): Record<string, string> {
    const baseParams = {
      zipcode: this.userZipCode,
      amount: this.userAmount.toString(),
      unloading_points: "1",
      deliveryTimes: "normal",
    };

    if (this.inputType === "pellets") {
      return {
        ...baseParams,
        quality_steps: "isoNorm, enPlus, dinPlus",
        payment_type: "all",
        prod: "loose",
        hose: "thirtyMetre",
        short_vehicle: "withTrailer",
      };
    }

    return {
      ...baseParams,
      payment_type: this.availablePayment,
      prod: "normal",
      hose: "fortyMetre",
      short_vehicle: "withTrailer",
    };
  }

  sendAnalyticsEvent() {
    sendGtmEvent(this, {
      category: "Navigation",
      label: `Nav CTA ${this.inputType.toUpperCase()} PV`,
      action: "submit",
    });
  }

  get availablePayment() {
    return this.availabilities.paymentTypes?.includes(this.paymentType)
      ? this.paymentType
      : this.availabilities.paymentTypes?.[0] || "all";
  }
}
