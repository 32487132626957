
import { Vue, Component, Prop } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import eventBus from "~/operations/event.bus";

interface MenuType {
  name: string;
  isSpacer?: boolean;
  isSubMenu?: boolean;
  title?: string;
  path?: string;
  isExternalLink?: boolean;
  children?: MenuType[];
}

@Component({
  name: "HeaderMenuItem",
  components: { HeaderMenuItem, RouterLink },
})
export default class HeaderMenuItem extends Vue {
  @Prop() data: MenuType;
  @Prop({ default: false }) isSubMenuItem: boolean;

  isExpanded = false;

  mounted() {
    // Expand main categories upon mount
    if (!this.data.title && !this.data.isSubMenu) {
      this.isExpanded = true;
    }
  }

  onMenuItemClick() {
    eventBus.$emit("header-menu-item-click");
  }

  toggleMenu() {
    this.isExpanded = !this.isExpanded;
  }
}
