// Libraries
import axios from "axios";
import dayjs from "dayjs";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { CaclulatorRequestParams } from "~/types/HeatingOilCalculator";

@Module({
  name: "caclulatorstore",
  namespaced: true,
  stateFactory: true,
})
export default class CaclulatorStore extends VuexModule {
  dealerOffersData = {
    errorMessage: "",
    dealerOffers: [],
  };
  availabilities = [];

  averageDeliveryTime = 20;
  minDeliveryTime = 0;

  currentTime = "";

  @Mutation
  setDealerOffersErrorMessage(message) {
    this.dealerOffersData.errorMessage = message;
  }

  @Mutation
  setMinDeliveryTime(data) {
    const min = Math.min(...data.data.map((ele) => ele.delivery.durationDays));
    this.minDeliveryTime = min;
  }

  @Mutation
  setDealerAverageDeliveryTime(data) {
    let sum = 0;
    data.data.forEach((dealer) => {
      sum += dealer.delivery.durationDays;
    });
    this.averageDeliveryTime = Math.floor(sum / data.data.length);
  }

  @Mutation
  setDealerOffers(data) {
    if (!process.server) {
      localStorage.setItem("orderToken", data.meta._token);
    }
    const fastestDelivery = data.data.reduce((fastest, item) => {
      const itemDate = new Date(item.delivery.date);
      const fastestDate = fastest ? new Date(fastest.delivery.date) : null;

      if (!fastestDate || itemDate < fastestDate) {
        return item;
      } else {
        return fastest;
      }
    }, null);

    this.dealerOffersData.dealerOffers = data.data.map((item) => {
      const itemDate = new Date(item.delivery.date);
      const fastestDate = new Date(fastestDelivery.delivery.date);

      item.fastestDelivery = itemDate.getTime() === fastestDate.getTime();
      return item;
    });
    this.currentTime = dayjs().format("HH:mm");
  }

  @Mutation
  setAvailabilities(data: any) {
    this.availabilities = data;
  }

  @Mutation
  sortDealerOffers(sortOptions: {
    sortByParameter: string;
    direction: "asc" | "desc";
    isPelletsSite?: boolean;
  }) {
    const getPriceValue = (offer) => {
      return Number(
        sortOptions.isPelletsSite
          ? offer.pricing.ton.brutto
          : offer.pricing._100L.brutto,
      );
    };

    const sortFunctions = {
      price: {
        asc: (a, b) => {
          const priceA = getPriceValue(a);
          const priceB = getPriceValue(b);
          return priceA - priceB;
        },
        desc: (a, b) => {
          const priceA = getPriceValue(a);
          const priceB = getPriceValue(b);
          return priceB - priceA;
        },
      },
      name: {
        asc: (a, b) =>
          a.dealer.name
            .toUpperCase()
            .localeCompare(b.dealer.name.toUpperCase()),
        desc: (a, b) =>
          b.dealer.name
            .toUpperCase()
            .localeCompare(a.dealer.name.toUpperCase()),
      },
      "delivery-date": {
        asc: (a, b) => a.delivery.date.localeCompare(b.delivery.date),
        desc: (a, b) => b.delivery.date.localeCompare(a.delivery.date),
      },
      rating: {
        asc: (a, b) => {
          const ratingA =
            a.dealer.rating.averageRating * a.dealer.rating.countRating || 0;
          const ratingB =
            b.dealer.rating.averageRating * b.dealer.rating.countRating || 0;
          return ratingA - ratingB;
        },
        desc: (a, b) => {
          const ratingA =
            a.dealer.rating.averageRating * a.dealer.rating.countRating || 0;
          const ratingB =
            b.dealer.rating.averageRating * b.dealer.rating.countRating || 0;
          return ratingB - ratingA;
        },
      },
    };

    const sortFn =
      sortFunctions[sortOptions.sortByParameter]?.[sortOptions.direction];

    if (sortFn) {
      this.dealerOffersData.dealerOffers.sort(sortFn);
    }
  }

  @Action
  async fetchDealerOffers(data) {
    this.context.commit("setDealerOffersErrorMessage", "");

    const url = data.isPelletsSite
      ? `${process.env.BACKBONE_ESYOIL}${process.env.CALC_PELLETS_DEALER_OFFERS}`
      : `${process.env.BACKBONE_ESYOIL}${process.env.CALC_DEALER_OFFERS}`;
    try {
      const result = await axios({
        method: "post",
        url,
        headers: { "Content-Type": "application/json" },
        data: data.body,
      });

      this.context.commit("setDealerOffers", result.data);
      this.context.commit("setDealerAverageDeliveryTime", result.data);
      this.context.commit("setMinDeliveryTime", result.data);
    } catch (error) {
      if (error.response.data.customStatusCode) {
        this.context.commit(
          "setDealerOffersErrorMessage",
          error.response.data.customStatusCode,
        );
      } else {
        this.context.commit("setDealerOffersErrorMessage", error.response.data);
      }
    }
  }

  @Action
  fetchAndSortDealerOffers(data: {
    isPelletsSite;
    dealerParameters: CaclulatorRequestParams;
    sortOptions: { sortByParameter: string; direction: "asc" | "desc" };
  }) {
    const url = data.isPelletsSite
      ? `${process.env.BACKBONE_ESYOIL}${process.env.CALC_PELLETS_DEALER_OFFERS}`
      : `${process.env.BACKBONE_ESYOIL}${process.env.CALC_DEALER_OFFERS}`;

    this.context.commit("setDealerOffersErrorMessage", "");
    axios({
      method: "post",
      url: url,
      headers: { "Content-Type": "application/json" },
      data: data.dealerParameters,
    })
      .then((response) => {
        this.context.commit("setDealerOffers", response.data);
        this.context.commit("sortDealerOffers", data.sortOptions);
      })
      .catch((error) => {
        console.error(error.response.data.customStatusCode);
        if (error.response.data.customStatusCode) {
          this.context.commit(
            "setDealerOffersErrorMessage",
            error.response.data.customStatusCode,
          );
        } else {
          this.context.commit(
            "setDealerOffersErrorMessage",
            error.response.data,
          );
        }
      });
  }

  @Action
  async fetchAvailableOptions(requestData: {
    isPellets: boolean;
    zipcode: string;
    amount: number;
    type?: string;
  }) {
    const url = requestData.isPellets
      ? `${process.env.BACKBONE_ESYOIL}${process.env.PELLETS_AVAILABILITY_CHECK}`
      : `${process.env.BACKBONE_ESYOIL}${process.env.AVAILABILITY_CHECK}`;

    if (requestData.zipcode && requestData.amount) {
      const data = requestData.isPellets
        ? {
            zipcode: requestData.zipcode,
            amount: requestData.amount,
            prod: requestData.type ? requestData.type : "loose",
          }
        : { zipcode: requestData.zipcode, amount: requestData.amount };
      try {
        const result = await axios({
          method: "post",
          // eslint-disable-next-line no-undef
          url,
          headers: { "Content-Type": "application/json" },
          data: data,
        });
        this.context.commit("setAvailabilities", result.data);
      } catch (error) {
        console.error(error);
      }
    }
  }
}
